import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { DetailHero } from "../components/DetailHero";
import { DetailImages } from "../components/DetailImages";
import { DetailTextSection } from "../components/DetailTextSection";
import { DetailNavigation } from "../components/DetailNavigation";
import Layout from "../components/Layout";
import MetaTags from "../components/MetaTags";
import { SupportCTA } from "../components/SupportCTA";
import ty from "../styled-tachyons";
import { BodyText, Image, PageHeader } from "../styles";

const EventDetailPageStyle = styled.div`
  ${Image} {
    max-width: 100%;
  }
  ${PageHeader} {
    ${ty`mb1`}

    & + ${BodyText} {
      ${ty`mt1 mb5`}
    }
  }
`;

export const ProjectDetailPageTemplate = ({
  project,
  artist = {},
  previousNode,
  nextNode,
}) => {
  let {
    link,
    coverImage,
    pageTitle,
    flagTitle,
    title,
    subTitle,
    subSubTitle,
    description_html,
  } = project;

  const navigation = (
    <DetailNavigation
      indexLink="/events"
      indexText="Events"
      textAttr="frontmatter.flagTitle"
      previousNode={previousNode}
      nextNode={nextNode}
    />
  );
  return (
    <EventDetailPageStyle>
      <MetaTags title={pageTitle} />
      <DetailHero
        navigation={navigation}
        pageTitle={pageTitle}
        link={link}
        coverImage={coverImage}
        cardTitle={flagTitle}
        title={title}
        subTitle={subTitle}
        subSubTitle={subSubTitle}
      />
      <DetailImages project={project} />
      <DetailTextSection artist={artist} />
      <DetailTextSection title="About the Event" html={description_html} />
      <SupportCTA noun="Events" />
    </EventDetailPageStyle>
  );
};

ProjectDetailPageTemplate.propTypes = {};

const ProjectDetailPage = (p) => {
  const { data } = p;
  const { frontmatter } = data.markdownRemark;
  //console.log(data);

  return (
    <Layout>
      <ProjectDetailPageTemplate
        title={frontmatter.title}
        project={frontmatter}
        artist={data.markdownRemark?.fields?.artistNode?.frontmatter}
        previousNode={data.markdownRemark?.fields?.previousNode}
        nextNode={data.markdownRemark?.fields?.nextNode}
      />
    </Layout>
  );
};

EventDetailPageStyle.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProjectDetailPage;

export const pageQuery = graphql`
  query EventDetailPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        previousNode {
          id
          fields {
            slug
          }
          frontmatter {
            title
            flagTitle
          }
        }
        nextNode {
          id
          fields {
            slug
          }
          frontmatter {
            title
            flagTitle
          }
        }
      }
      frontmatter {
        pageTitle
        flagTitle

        title
        subTitle
        subSubTitle
        link
        description_html

        coverImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        detailImage {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        topDetailImage {
          ...smallDetailImage
        }
        bottomDetailImage {
          ...smallDetailImage
        }
      }
    }
  }
`;
